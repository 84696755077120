import { useState, useRef, useEffect, SyntheticEvent } from 'react';

import { useMediaQuery } from '@mui/material';
import axios, { CancelTokenSource } from 'axios';
import imageCompression from 'browser-image-compression';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useDropzone } from 'react-dropzone';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useAccount } from 'wagmi';

import { useUploadMedia } from '@/api/uploadFile';
import errorMessages from '@/utils/errorMessages.json';
import { sendNotifcation } from '@/utils/notification';
import { IFileUploadZoneProps } from 'interfaces/File/file';
import AudioIcon from 'public/assets/audio2.svg';
import CloseImg from 'public/assets/close.svg';
import ImageIcon from 'public/assets/image2.svg';
import MoreIcon from 'public/assets/more2.svg';
import UploadIcon from 'public/assets/upload3.svg';
import VideoIcon from 'public/assets/video.svg';

import { isBrowser } from '../../constants/index';
import { getFilesFromLocalstorage } from '../../utils/index';

const Canvas = dynamic(() => import('../Excalidraw/Canvas'), { ssr: false });

const supportedFileTypes = '*';

export default function FileUploadZone({
  authenticated,
  setErrorPage,
}: IFileUploadZoneProps) {
  const { data: signer } = useAccount();
  const [areFilesInLS, setAreFilesInLS] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [createFileOpen, setCreateFileOpen] = useState<boolean>(false);
  const [files, setFiles] = useState<Array<File & { previewURL: string }>>([]);
  const [progress, setProgress] = useState(0);
  const [canvasOpen, setCanvasOpen] = useState<boolean>(false);
  const [cancelTokenSource, setCancelTokenSource] =
    useState<CancelTokenSource | null>(null);
  const hiddenFileInput = useRef(null);
  const imageInput = useRef(null);
  const audioInput = useRef(null);
  const videoInput = useRef(null);
  const isMediaMax1025px = useMediaQuery('(max-width : 1025px)');
  const isMediaMax1326px = useMediaQuery('(max-width : 1326px)');
  const isMediaMax570px = useMediaQuery('(max-width : 570px)');
  const isMedia640px = useMediaQuery('(max-width : 640px)');

  const { mutateAsync: uploadMedia } = useUploadMedia();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const router = useRouter();

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.previewURL));
    },
    [files]
  );

  useEffect(() => {
    if (isBrowser && getFilesFromLocalstorage('files').length > 0) {
      setAreFilesInLS(true);
    }
  }, [areFilesInLS]);

  const handleFileUpload = async () => {
    if (
      !signer?.address &&
      isBrowser &&
      getFilesFromLocalstorage('files').length > 10
    ) {
      sendNotifcation('Max files allowed are 10.', '', 'danger');
      return;
    }

    const fileOriginal = files.length > 0 ? files[0] : null;

    setIsLoading(true);
    const options = {
      maxSizeMB: 1000,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    if (fileOriginal) {
      let file = fileOriginal;
      if (fileOriginal.type.includes('image')) {
        file = (await imageCompression(fileOriginal, options)) as any;
      }

      const source = axios.CancelToken.source();
      setCancelTokenSource(source);

      if (fileOriginal.size > 20971520) {
        cancelTokenSource?.cancel();
        setIsLoading(false);
        sendNotifcation('Maximum allowed file size is 20MB', '', 'danger');
        return;
      }

      try {
        const onUploadProgress = (progressEvent: any) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        };
        const recaptchaToken = await executeRecaptcha('file');
        const result = await uploadMedia({
          file,
          name: file.name,
          config: {
            onUploadProgress,
            cancelToken: source.token,
          },
          recaptchaToken,
        });
        if (
          (result.status === 200 || result.status === 201) &&
          result.data?.uuid
        ) {
          const FILES_KEY = signer?.address || 'files';
          const filesInLS = getFilesFromLocalstorage(FILES_KEY);

          if (!signer?.address) {
            setAreFilesInLS(true);
          }

          localStorage.setItem(
            FILES_KEY,
            JSON.stringify([...filesInLS, result?.data])
          );

          router.push(
            `files/${result?.data?.uuid}?editAudience=true&ownerFirstVisit=true`
          );
        }
      } catch (error: any) {
        if (error.response?.status >= 500) {
          setErrorPage(true);
          return;
        }
        switch (error.response?.status) {
          case 429: {
            sendNotifcation(error.response.data.message, '', 'danger');
            break;
          }
          case 403: {
            sendNotifcation(errorMessages[403], '', 'danger');
            break;
          }
          default: {
            sendNotifcation('Unable to Upload file', '', 'danger');
            break;
          }
        }
        cancelTokenSource?.cancel();
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (files && files.length > 0) {
      handleFileUpload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const selectFile = (acceptedFiles: File[]) => {
    const list = Array.from(acceptedFiles);

    setFiles(
      list.map((file) => {
        return Object.assign(file, {
          previewURL: URL.createObjectURL(file),
        });
      })
    );
  };

  const handleDrop = (acceptedFiles: File[]) => {
    selectFile(acceptedFiles);
  };

  const onSelectClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  const onImageSelectClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    imageInput.current.click();
  };
  const onVideoSelectClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    videoInput.current.click();
  };
  const onAudioSelectClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    audioInput.current.click();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    maxFiles: 1,
    noClick: true,
  });

  const uploadBtnClass = `mx-2 drop-shadow-lg bg-white px-6 flex justify-center items-center py-3 mt-4 rounded-md font-light ${
    isMedia640px ? 'mx-1 px-2 text-sm' : ''
  }`;

  if (canvasOpen)
    return (
      <div className="absolute top-24 bottom-12 right-12 left-12 rounded-md ">
        <Canvas setCanvasOpen={setCanvasOpen} setFiles={setFiles} />
      </div>
    );

  return (
    <div
      {...getRootProps()}
      className={`${
        isMediaMax1025px ? 'w-full' : ' w-[80%]'
      }  h-[23rem] bg-white  rounded-lg p-4 m-auto flex relative cursor-default ${
        isMediaMax1326px ? 'h-[27rem]' : {}
      } ${isMediaMax570px ? 'h-[22rem]' : {}}`}
      onClick={onSelectClick}
    >
      {isLoading ? (
        <section
          className="border-2  w-full relative rounded-lg grid items-center text-center border-dashed border-gray-400 "
          onClick={(e) => e.stopPropagation()}
        >
          <div className="w-full">
            <div>
              <h3 className="mb-10 text-xl font-semibold text-center">
                Uploading
              </h3>
            </div>
            {files.length > 0 && files[0]?.name.length > 25
              ? `${files[0]?.name.substring(
                  0,
                  20
                )}...${files[0]?.name.substring(files[0]?.name.indexOf('.'))}`
              : files[0]?.name}
            <div className="border-2 relative border-gray-400 border-dashed flex pl-4 pr-1 py-1 rounded-lg h-16 w-[15rem] mx-auto">
              <div className="w-full px-2 my-auto ">
                <div className="relative w-full h-2 bg-gray-200 rounded-full">
                  <div
                    style={{ width: `${progress}%` }}
                    className="bg-prp absolute inset-0 rounded-full z-10"
                  />
                </div>
              </div>

              <button
                onClick={() => cancelTokenSource?.cancel()}
                className="grid w-20 h-full ml-4 bg-yellow rounded-xxs place-items-center"
              >
                <img src={CloseImg.src} alt="closeImg" />
              </button>
            </div>
          </div>
        </section>
      ) : (
        <>
          {isDragActive && (
            <div className="absolute inset-0 grid place-content-center bg-white opacity-40 rounded-lg" />
          )}
          <input
            multiple={true}
            type={'file'}
            ref={hiddenFileInput}
            accept={supportedFileTypes}
            style={{ display: 'none' }}
            onChange={(e) => {
              const list = Array.from(e.target.files);
              return selectFile(list);
            }}
          />
          <div className="h-full w-full no-scrollbar flex flex-col justify-between">
            <div></div>
            <div
              className={`flex flex-col items-center ${
                isMedia640px ? 'pt-12' : 'pt-6'
              } ${isMediaMax570px ? 'pt-8' : {}}`}
            >
              {!authenticated && (
                <>
                  <div className="text-center font-semibold text-2xl">
                    Just upload and share!
                  </div>
                  <div className="text-lg text-center">
                    You upload, you encrypt, IPFS & Arweave store.
                  </div>
                </>
              )}
              <div className="px-8 py-7 my-5 mt-12 rounded-full bg-yellow border-dashed border border-black">
                <img
                  src={UploadIcon.src}
                  alt="Upload Files"
                  className={`${isMediaMax570px ? 'h-8' : 'h-12'} `}
                />
              </div>
              {authenticated && (
                <span className="font-light text-center">
                  Drag and drop your files here.
                </span>
              )}

              <input {...getInputProps()} />
            </div>
            <div className="w-full">
              <div className={` flex flex-row w-full flex-wrap justify-center`}>
                <button className={uploadBtnClass} onClick={onImageSelectClick}>
                  <img src={ImageIcon.src} alt="Image Input" />
                  <span className="ml-2">Image</span>
                  <input
                    multiple={true}
                    type={'file'}
                    ref={imageInput}
                    accept="image/*"
                    className="hidden"
                    onChange={(e) => {
                      const list = Array.from(e.target.files);
                      return selectFile(list);
                    }}
                  />
                </button>
                <button className={uploadBtnClass} onClick={onVideoSelectClick}>
                  <img src={VideoIcon.src} alt="Image Input" />
                  <span className="ml-2">Video</span>
                  <input
                    multiple={true}
                    type={'file'}
                    ref={videoInput}
                    accept="video/*"
                    className="hidden"
                    onChange={(e) => {
                      const list = Array.from(e.target.files);
                      return selectFile(list);
                    }}
                  />
                </button>
                <button className={uploadBtnClass} onClick={onAudioSelectClick}>
                  <img src={AudioIcon.src} alt="Image Input" />
                  <span className="ml-2">Audio</span>
                  <input
                    multiple={true}
                    type={'file'}
                    ref={audioInput}
                    accept="audio/mpeg"
                    className="hidden"
                    onChange={(e) => {
                      const list = Array.from(e.target.files);
                      return selectFile(list);
                    }}
                  />
                </button>
                {/* <button className={uploadBtnClass} onClick={onThreeDClick}>
                  <img src={CubeIcon.src} alt="3D Input" className="h-6" />
                  <span className="ml-2">3D Files</span>
                  <input
                    multiple={true}
                    type={'file'}
                    ref={threeDInput}
                    accept=".glb, .gltf"
                    className="hidden"
                    onChange={(e) => {
                      const list = Array.from(e.target.files);
                      return selectFile(list);
                    }}
                  />
                </button>
                <button className={uploadBtnClass} onClick={onAnyClick}>
                  <img src={FileIcon.src} alt="All" className="h-6" />
                  <span className="ml-2">More</span>
                  <input
                    multiple={true}
                    type={'file'}
                    ref={anyInput}
                    accept="*"
                    className="hidden"
                    onChange={(e) => {
                      const list = Array.from(e.target.files);
                      return selectFile(list);
                    }}
                  />
                </button> */}
                <button
                  className={`${uploadBtnClass} relative`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setCreateFileOpen((b) => !b);
                  }}
                >
                  <img src={MoreIcon.src} alt="More" />
                  {createFileOpen && (
                    <div
                      className={` bottom-[-50px] ${
                        isMediaMax1025px ? 'left-[-65px]' : 'left-[-30px]'
                      }  absolute`}
                    >
                      {/* <div
                        onClick={() => {
                          setCanvasOpen(true);
                        }}
                        className={`"text-sm w-32 bg-white 
                      hover:bg-gray-200 py-1 border rounded-sm`}
                      >
                        Draw
                      </div> */}
                      <div
                        onClick={() => {
                          router.push('/create');
                        }}
                        className={`"text-sm w-32   bg-white 
                      hover:bg-gray-200 py-1 border rounded-sm`}
                      >
                        Create Notes
                      </div>
                    </div>
                  )}
                </button>
              </div>
              {/* <div
                className={`text-center mt-[2.5rem] font-semibold border-b-2 border-black w-max mx-auto ${
                  isMediaMax570px ? 'mt-[1.5rem]' : {}
                }`}
                onClick={(e) => e.stopPropagation()}
              >
                {!authenticated && (
                  <Link href="/why-fileverse" passHref={true}>
                    <span className="cursor-pointer">Why Fileverse?</span>
                  </Link>
                )}
              </div> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
